import loadable from "@loadable/component"
import Container from "@material-ui/core/Container"
import React from "react"
import { Helmet } from "react-helmet"
import styled, { css } from "styled-components"
import { flatten } from "../../helpers"
import {
  OverviewDataFragment,
  PageBuilderActiviteitBlocksFragment,
  PageBuilderBlocksFragment,
  PageBuilderErvaringBlocksFragment,
  PageBuilderNieuwsBlocksFragment,
  PageBuilderVacatureBlocksFragment,
} from "../../__generated__/graphql-gatsby"
import Agenda from "./agenda"

const ButtonGroup = loadable(() => import("./ButtonGroup"))
const CTA = loadable(() => import("./cta"))
const FAQ = loadable(() => import("./faq"))
const Facebook = loadable(() => import("./facebook"))
const Featured = loadable(() => import("./featured"))
const FormBlock = loadable(() => import("./form"))
const Img = loadable(() => import("./img"))
const Latest = loadable(() => import("./latest"))
const Overview = loadable(() => import("./overview"))
const Spacer = loadable(() => import("./spacer"))
const Text = loadable(() => import("./text"))
const Youtube = loadable(() => import("./youtube"))

interface IProps {
  blocks: Maybe<
    Array<
      | PageBuilderBlocksFragment
      | PageBuilderErvaringBlocksFragment
      | PageBuilderActiviteitBlocksFragment
      | PageBuilderNieuwsBlocksFragment
      | PageBuilderVacatureBlocksFragment
      | null
    >
  >
  overviewData?: Maybe<Array<Maybe<OverviewDataFragment>>>
  sessionKey: string
}

export const spacing = css`
  margin-top: ${({ theme }) => theme.spacing(6)}px;
  margin-bottom: ${({ theme }) => theme.spacing(6)}px;
`

const Wrapper = styled.div`
  max-width: 1920px;
  margin: 0 auto;
  padding: ${({ theme }) => theme.spacing(1)}px;
`

const PageBuilder: React.FC<IProps> = ({
  blocks,
  overviewData,
  sessionKey,
}) => {
  const FAQjsonLD = blocks
    ?.map(block => {
      if (block) {
        if (
          block.__typename === "WpPage_PageBuilder_PageBuilder_Faq" ||
          block.__typename === "WpErvaring_PageBuilder_PageBuilder_Faq" ||
          block.__typename === "WpActiviteit_PageBuilder_PageBuilder_Faq" ||
          block.__typename === "WpNieuws_PageBuilder_PageBuilder_Faq" ||
          block.__typename === "WpVacature_PageBuilder_PageBuilder_Faq"
        ) {
          if (block.metaData) {
            return {
              "@type": "Question",
              name: block.titel,
              acceptedAnswer: {
                "@type": "Answer",
                text: block.tekst,
              },
            }
          }
        }
      }
    })
    .filter(Boolean)

  return (
    <>
      {FAQjsonLD && FAQjsonLD.length > 0 ? (
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "FAQPage",
              mainEntity: FAQjsonLD,
            })}
          </script>
        </Helmet>
      ) : null}

      <Container maxWidth="xl" disableGutters>
        <Wrapper id="wp_gatsby_pagebuilder">
          {blocks?.map((block, i) => {
            if (!block) return null

            switch (block.__typename) {
              case "WpPage_PageBuilder_PageBuilder_TekstEditor":
              case "WpErvaring_PageBuilder_PageBuilder_TekstEditor":
              case "WpActiviteit_PageBuilder_PageBuilder_TekstEditor":
              case "WpNieuws_PageBuilder_PageBuilder_TekstEditor":
              case "WpVacature_PageBuilder_PageBuilder_TekstEditor":
                return <Text key={i} block={block} />

              case "WpPage_PageBuilder_PageBuilder_Spacer":
              case "WpErvaring_PageBuilder_PageBuilder_Spacer":
              case "WpActiviteit_PageBuilder_PageBuilder_Spacer":
              case "WpNieuws_PageBuilder_PageBuilder_Spacer":
              case "WpVacature_PageBuilder_PageBuilder_Spacer":
                return <Spacer key={i} height={block.hoogte} />

              case "WpPage_PageBuilder_PageBuilder_Afbeelding":
              case "WpErvaring_PageBuilder_PageBuilder_Afbeelding":
              case "WpActiviteit_PageBuilder_PageBuilder_Afbeelding":
              case "WpNieuws_PageBuilder_PageBuilder_Afbeelding":
              case "WpVacature_PageBuilder_PageBuilder_Afbeelding":
                return <Img key={i} block={block} />

              case "WpPage_PageBuilder_PageBuilder_Cta":
              case "WpErvaring_PageBuilder_PageBuilder_Cta":
              case "WpActiviteit_PageBuilder_PageBuilder_Cta":
              case "WpNieuws_PageBuilder_PageBuilder_Cta":
              case "WpVacature_PageBuilder_PageBuilder_Cta":
                return <CTA key={i} block={block} />

              case "WpPage_PageBuilder_PageBuilder_Faq":
              case "WpErvaring_PageBuilder_PageBuilder_Faq":
              case "WpActiviteit_PageBuilder_PageBuilder_Faq":
              case "WpNieuws_PageBuilder_PageBuilder_Faq":
              case "WpVacature_PageBuilder_PageBuilder_Faq":
                return <FAQ key={i} block={block} />

              case "WpPage_PageBuilder_PageBuilder_UitgelichteActiviteit":
              case "WpErvaring_PageBuilder_PageBuilder_UitgelichteActiviteit":
              case "WpActiviteit_PageBuilder_PageBuilder_UitgelichteActiviteit":
              case "WpNieuws_PageBuilder_PageBuilder_UitgelichteActiviteit":
              case "WpVacature_PageBuilder_PageBuilder_UitgelichteActiviteit":
                return (
                  <Featured
                    key={i}
                    featured={block.activiteit}
                    flip={block.flip}
                    onlyImg={block.onlyImg}
                  />
                )

              case "WpPage_PageBuilder_PageBuilder_UitgelichteErvaring":
              case "WpErvaring_PageBuilder_PageBuilder_UitgelichteErvaring":
              case "WpActiviteit_PageBuilder_PageBuilder_UitgelichteErvaring":
              case "WpNieuws_PageBuilder_PageBuilder_UitgelichteErvaring":
              case "WpVacature_PageBuilder_PageBuilder_UitgelichteErvaring":
                return (
                  <Featured
                    key={i}
                    featured={block.ervaring}
                    flip={block.flip}
                    onlyImg={block.onlyImg}
                  />
                )

              case "WpPage_PageBuilder_PageBuilder_LaatsteBerichten":
              case "WpErvaring_PageBuilder_PageBuilder_LaatsteBerichten":
              case "WpActiviteit_PageBuilder_PageBuilder_LaatsteBerichten":
              case "WpNieuws_PageBuilder_PageBuilder_LaatsteBerichten":
              case "WpVacature_PageBuilder_PageBuilder_LaatsteBerichten":
                return <Latest key={i} block={block} />

              case "WpPage_PageBuilder_PageBuilder_Agenda":
              case "WpErvaring_PageBuilder_PageBuilder_Agenda":
              case "WpActiviteit_PageBuilder_PageBuilder_Agenda":
              case "WpNieuws_PageBuilder_PageBuilder_Agenda":
              case "WpVacature_PageBuilder_PageBuilder_Agenda":
                return <Agenda key={i} block={block} />

              case "WpPage_PageBuilder_PageBuilder_Formulier":
              case "WpErvaring_PageBuilder_PageBuilder_Formulier":
              case "WpActiviteit_PageBuilder_PageBuilder_Formulier":
              case "WpNieuws_PageBuilder_PageBuilder_Formulier":
              case "WpVacature_PageBuilder_PageBuilder_Formulier":
                return <FormBlock key={i} block={block} />

              case "WpPage_PageBuilder_PageBuilder_OverzichtPaginas":
              case "WpErvaring_PageBuilder_PageBuilder_OverzichtPaginas":
              case "WpActiviteit_PageBuilder_PageBuilder_OverzichtPaginas":
              case "WpNieuws_PageBuilder_PageBuilder_OverzichtPaginas":
              case "WpVacature_PageBuilder_PageBuilder_OverzichtPaginas":
                return overviewData ? (
                  <Overview
                    key={i}
                    data={overviewData}
                    format={block.format}
                    sort={block.sortering}
                    tags={block.tags?.map(flatten)}
                    sessionKey={sessionKey}
                  />
                ) : null

              case "WpPage_PageBuilder_PageBuilder_Youtube":
              case "WpErvaring_PageBuilder_PageBuilder_Youtube":
              case "WpActiviteit_PageBuilder_PageBuilder_Youtube":
              case "WpNieuws_PageBuilder_PageBuilder_Youtube":
              case "WpVacature_PageBuilder_PageBuilder_Youtube":
                return <Youtube key={i} block={block} />

              case "WpPage_PageBuilder_PageBuilder_Facebook":
              case "WpErvaring_PageBuilder_PageBuilder_Facebook":
              case "WpActiviteit_PageBuilder_PageBuilder_Facebook":
              case "WpNieuws_PageBuilder_PageBuilder_Facebook":
              case "WpVacature_PageBuilder_PageBuilder_Facebook":
                return <Facebook key={i} block={block} />

              case "WpPage_PageBuilder_PageBuilder_ButtonsGroup":
              case "WpErvaring_PageBuilder_PageBuilder_ButtonsGroup":
              case "WpActiviteit_PageBuilder_PageBuilder_ButtonsGroup":
              case "WpNieuws_PageBuilder_PageBuilder_ButtonsGroup":
              case "WpVacature_PageBuilder_PageBuilder_ButtonsGroup":
                return <ButtonGroup key={i} block={block} />

              default:
                return null
            }
          })}
        </Wrapper>
      </Container>
    </>
  )
}

export default PageBuilder
