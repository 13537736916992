import { lighten } from "@material-ui/core"
import Container from "@material-ui/core/Container"
import { navigate } from "gatsby"
import moment from "moment"
import "moment/locale/nl"
import React, { SyntheticEvent } from "react"
import { Calendar, momentLocalizer } from "react-big-calendar"
import "react-big-calendar/lib/css/react-big-calendar.css"
import styled from "styled-components"
import { flatten, hasCommonElements } from "../../helpers"
import useAllActivities, { EventData } from "../../hooks/useAllActivities"
import {
  PageBuilderActiviteitAgendaFragment,
  PageBuilderAgendaFragment,
  PageBuilderErvaringAgendaFragment,
  PageBuilderNieuwsAgendaFragment,
  PageBuilderVacatureAgendaFragment,
} from "../../__generated__/graphql-gatsby"

interface IProps {
  block:
    | PageBuilderAgendaFragment
    | PageBuilderActiviteitAgendaFragment
    | PageBuilderErvaringAgendaFragment
    | PageBuilderNieuwsAgendaFragment
    | PageBuilderVacatureAgendaFragment
}

moment.locale("nl")
const localizer = momentLocalizer(moment)

const dutch = {
  agenda: "Agenda",
  allDay: "Hele dag",
  month: "Maand",
  day: "Dag",
  today: "Vandaag",
  previous: "Vorige",
  next: "Volgende",
  date: "Datum",
  noEventsInRange: "Er zijn geen agenda-items in deze periode",
  time: "Tijd",
  tomorrow: "Morgen",
  week: "Week",
  work_week: "Werkweek",
  yesterday: "Gisteren",
  showMore: () => "Toon meer",
}

const BigCalendar = styled(props => <Calendar {...props} />)`
  margin-top: ${({ theme }) => theme.spacing(6)}px;
  margin-bottom: ${({ theme }) => theme.spacing(6)}px;
  height: 720px;

  font-family: Roboto;

  button {
    cursor: pointer;

    &:hover,
    &:focus {
      background-color: ${({ theme }) =>
        lighten(theme.palette.secondary.light, 0.66)};
    }

    &.rbc-active {
      background-color: ${({ theme }) =>
        lighten(theme.palette.secondary.light, 0.33)};

      &:hover,
      &:focus {
        background-color: ${({ theme }) =>
          lighten(theme.palette.secondary.light, 0.5)};
      }
    }
  }

  .rbc-today {
    background-color: ${({ theme }) => theme.palette.background.paper};
  }

  .rbc-off-range-bg {
    background-color: ${({ theme }) =>
      lighten(theme.palette.secondary.light, 0.33)};
  }

  .rbc-header {
    background-color: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.contrastText};
  }

  .rbc-event {
    background-color: ${({ theme }) => theme.palette.primary.main};
    transition: ${({ theme }) => theme.transitions.create("background-color")};
    border-color: ${({ theme }) => theme.palette.primary.dark};

    &:hover {
      background-color: ${({ theme }) => theme.palette.primary.dark};
    }
  }

  .rbc-agenda-empty {
    text-align: center;
  }
`

const Agenda: React.FC<IProps> = ({ block }) => {
  const allActivities = useAllActivities()

  let activities
  if (block.tags) {
    activities = allActivities.filter(activity =>
      hasCommonElements(
        activity.terms?.nodes?.map(flatten),
        block.tags?.map(flatten)
      )
    )
  } else {
    activities = allActivities
  }

  const defaultView =
    block.weergave === "week"
      ? "week"
      : block.weergave === "list"
      ? "agenda"
      : "month"

  const handleClick = (event: EventData, e: SyntheticEvent) => {
    e.preventDefault()
    navigate(event.uri)
  }

  return (
    <Container>
      <BigCalendar
        localizer={localizer}
        events={activities}
        defaultView={defaultView}
        startAccessor="start"
        endAccessor="end"
        messages={dutch}
        onSelectEvent={handleClick}
      />
    </Container>
  )
}

export default Agenda
