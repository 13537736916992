import { graphql, useStaticQuery } from "gatsby"
import moment from "moment"
import { AllActivitiesQuery } from "../__generated__/graphql-gatsby"

export type Term = { id: string }

export type Terms = Maybe<{
  nodes?: Maybe<Array<Maybe<Term>>>
}>

export type EventData = {
  id: string
  title: string
  start: Date
  terms: Terms
  end: Date
  uri: string
}

type CreateDate = (date: string, time: string) => Date
const createDate: CreateDate = (date, time) =>
  new Date(moment(`${date} ${time}`).format())

const useAllActivities = () => {
  const query = useStaticQuery<AllActivitiesQuery>(graphql`
    query AllActivities {
      allWpActiviteit {
        nodes {
          id
          title
          uri
          activiteit_fields {
            terugkerendeActiviteit
            startTijd
            eindTijd
            datum
            dates {
              dates_collection
            }
            heeftAanvullendeData
            aanvullendeData {
              datum
              startTijd
              eindTijd
            }
            uitgeslotenData {
              datum
              startTijd
              eindTijd
            }
          }
          terms {
            nodes {
              id
            }
          }
        }
      }
    }
  `)

  const dateFormat = "YYYY-MM-DD"
  const defaultTime = "00:00"

  const data = query.allWpActiviteit.nodes.reduce<EventData[]>((acc, val) => {
    const id = val.id
    const title = String(val.title)
    const uri = String(val.uri)
    const terms = val.terms

    if (val.activiteit_fields?.terugkerendeActiviteit) {
      const startTime = val.activiteit_fields?.startTijd || defaultTime
      const endTime = val.activiteit_fields?.eindTijd || defaultTime

      const exclusions =
        val.activiteit_fields?.uitgeslotenData?.map(data => {
          const datum = moment(data?.datum).format(dateFormat)
          const startTime = data?.startTijd || defaultTime
          const endTime = data?.eindTijd || defaultTime

          return {
            start: createDate(datum, startTime),
            end: createDate(datum, endTime),
          }
        }) || []

      const list =
        val.activiteit_fields.dates?.dates_collection
          ?.map(date => {
            const datum = moment(date).format(dateFormat)
            return {
              id,
              title,
              uri,
              terms,
              start: createDate(datum, startTime),
              end: createDate(datum, endTime),
            }
          })
          .filter(activity => {
            const activityStart = new Date(activity.start)
            const activityEnd = new Date(activity.end)

            let returnActivity = true

            exclusions.forEach(exclusion => {
              const exclusionStart = new Date(exclusion.start)
              const exclusionEnd = new Date(exclusion.end)

              if (
                activityStart >= exclusionStart &&
                activityEnd <= exclusionEnd
              ) {
                returnActivity = false
              }
            })

            return returnActivity
          }) || []

      return [...acc, ...list]
    } else if (val.activiteit_fields?.heeftAanvullendeData) {
      const list =
        val.activiteit_fields.aanvullendeData?.map(date => {
          const datum = moment(date?.datum).format(dateFormat)
          const startTijd = date?.startTijd || defaultTime
          const eindTijd = date?.eindTijd || defaultTime

          return {
            id,
            title,
            uri,
            terms,
            start: createDate(datum, startTijd),
            end: createDate(datum, eindTijd),
          }
        }) || []

      return [...acc, ...list]
    } else {
      const date = moment(new Date(val.activiteit_fields?.datum || 0)).format(
        dateFormat
      )
      const startTime = val.activiteit_fields?.startTijd || defaultTime
      const endTime = val.activiteit_fields?.eindTijd || defaultTime

      return [
        ...acc,
        {
          id,
          title,
          uri,
          terms,
          start: createDate(date, startTime),
          end: createDate(date, endTime),
        },
      ]
    }
  }, [])

  return data
}

export default useAllActivities
